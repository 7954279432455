/*contact style*/
@import "../../global.scss";


  .contactBox{    
      display:flex;
      flex:1;      
      min-height:0%;
      border-radius:5px; 
  }
form{  
    translate:middle;
    color:#777;
    padding:.5rem;
    margin:2rem auto; 
    max-width: 500px;  
    margin:5rem auto; 
    background-color: #fff;
    box-shadow: 20px 0 20px 0 #999;

  
}
label{
    text-transform: uppercase;
    font-size: 14px;    
}
input, textarea{
    border:2px solid #777;
    padding:5rem;
    width:100%;
}
.formBtn{  
    width:auto;
    padding: .5rem;    
    text-transform: uppercase;
    color: $mainColor;
    background-color:rgb(221, 216, 216);
    margin:1rem 0;    
    font-size:16px;
    border:none;
    border-radius:15px;  

    &:hover{
        color:white;
        background:#777;
    }
}
