@import "../../global.scss";
//resume style

.resumeImgContainer{  
    display:flex;
    align-items: center;
    flex-direction: column;      
       
}
.resumewrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;   
}

.resumePdf{  
    
    height: 20rem;
    width:50vw;
    display:flex;
    flex-direction: column;  
    align-items: center;
    text-align: center;
    text-decoration: none;
    color:$mainColor;
    padding:10px;   
    overflow: hidden;  
   
}
