
//intro Style

.intro{
  height:100%;
}
.imgContainer{
padding-top: 50px;
}
.introPar{
   height:100vh;
    padding:15px;
    text-align: justify;
    overflow: hidden;                    
}  
  //intro image style

.introImg{
  max-width: 100%;
  max-height: 100%;
  display: block;
  
  }

 