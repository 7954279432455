@import "../../global.scss";

.gridCardContainer{
  display: grid; 
  margin-top: 8em;   
  }

h2,h3,h4,h6{
     color: #4b4c4d; 
    text-align: center;   
}
h3{
    background-color: rgba(142, 231, 161, 0.575);
    text-align: center;  
    justify-content: center;
}

.cardImage{
    max-width: 100%;
    display:block;
    overflow:hidden;
}
.cardTitle{    
text-align:start;
}
.card{    
    box-shadow: 0  0 3px rgba(0,0,0,0.9);
    border-radius: 15; 
    margin: 0 2em 2em 2em;  
    max-width:500px; 
    flex-wrap: nowrap;    
}

.cardImg{
    height:11rem;
    width:100%;
    display: block;
}

.cardTitle{
    padding: .6rem;
    font-size: medium;
}
.cardDescription{
    line-height: 1.5;
    font-size: 0.9em;;
    padding: 10px;
    justify-content: space-between;
}
.cardBtn{    
    padding: .5rem;
    font-family: inherit;
    font-weight: bold;       
    margin:1rem;
    border: 2px solid color $mainColor;
    font-size: 1rem;
    background-color: transparent;
    color:$mainColor;
    border-radius: .3rem;   
    text-align: center; 
}    
   
@media (min-width:600px)
{
    .gridCardContainer{
        display:grid;
        grid-template-columns: 1fr  1fr;
        gap:5px;         
     
      }
    .card{            
               
             box-shadow: 0  0 3px rgba(0,0,0,0.9);
             border-radius: 15; 
             margin: 0 2em 2em 2em;  
             max-width:300px; 
             flex-wrap: nowrap;
         
        cursor: pointer;
        transition:transform 350ms ease-in;
    
        &:hover{
            transform: scale(1.02);
        }
        &:hover button{
            background: $mainColor;
            color:white;
        }
    }

    .cardBtn{        
        padding: .5rem;
        font-family: inherit;
        font-weight: bold;        
        margin:1rem;
        border: 2px solid $mainColor;
        font-size: 1rem;
        background-color: transparent;
        color:$mainColor;
        border-radius: .3rem;
        transition: background-color 200ms ease0in, color 200ms ease-in;
    }

}

@media (min-width:800px){
    .gridCardContainer{
        display:grid;
        grid-template-columns: 1fr 1fr 1fr;
        display:grid;         
        gap:5px;     
        max-width: 1400px;            
      }
   
    .card{ 
        flex-wrap: nowrap;
        border-radius: 15; 
        margin: 0 2em 2em 2em;  
        max-width:300px;    
        box-shadow: 0  0 3px rgba(0,0,0,0.9);
        cursor: pointer;
        transition:transform 350ms ease-in;
    
        &:hover{
            transform: scale(1.02);
        }
        &:hover button{
            background: $mainColor;
            color:white;
        }
    }
    .cardBtn{
        padding: .5rem;
        font-family: inherit;
        font-weight: bold;        
        margin:1rem;
        border: 2px solid $mainColor;
        font-size: 1rem;
        background-color: transparent;
        color:$mainColor;
        border-radius: .3rem;
        transition: background-color 200ms ease-in, color 200ms ease-in;
    }

}