@import "../../global.scss";

.blogWrapper
{    
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   background-color: rgba(217, 233, 221, 0.575);
   align-items: center;
   justify-content: center;
   height:500px;
   max-width:500px;
}
p{
     text-align: justify;
     padding:50px;
    
 }
 .blogBtn{  
    width:auto;
    padding: .5rem;    
    text-transform: uppercase;
    color: $mainColor;
    background-color:rgb(221, 216, 216);
    margin:1rem 0;    
    font-size:16px;
    border:none;
    border-radius:5px;  

    &:hover{
        color:white;
        background:#777;
    }
 }