
*{
    box-sizing: border-box;
    padding:0;
    margin:0;     
}

body{   
    min-height: 100%;
    line-height: 1.5;
    font-family:   'Poppins', Helvetica , sans-serif, Arial ;   
   
}

.app{
    height: 100vh;   
  
    .sections{
       width:100%;
       height:calc(100vh - 70px);
       background-color:white;
       position:relative;
       top: 70px;
       scroll-behavior:smooth;
       scroll-snap-type: y mandatory;
       scrollbar-width:none;//for firefox
       &::-webkt-scrollbar{
           display:none;
       }

       > *{
        background-color: lightgreen;     
        width:100vw;
        height:calc(100vh - 70px);
        scroll-snap-align: start;
        
       }
    }
}

.intro,.resume,.blog,.contact{
    display: flex;
    align-items: center;
    flex-direction: column;
     background-color: lightgreen; 
    background:linear-gradient(120deg,#eff1f0,#edf3f0); 
    justify-content: center;
    height: 100vh;
    
    }
   
button:hover{
    cursor:pointer;
    }
    a{
        text-decoration: none;
    }
    h4,h6{
        color:#4b4c4d; 
        text-align: center;
        padding-bottom:0px; 
}
h2{
    
    display: flex;
    flex-direction: column;       
    background-color: rgba(142, 231, 161, 0.575);
    align-items: center;
    justify-content: center;
    color: #4b4c4d; 
    width:100vw;  
    min-height: 100px;

   
}
h6{
height: 30px;
}
.cardTitle{
    height:50px;
}    
img{
    max-width: 100%;
    display:block;
}
 
   
@media only screen and (max-width: 1900px){
     
    p{
        font-size: 1.25rem;    
        line-height: 1.5;
        padding:.5em; 
           
    }    
    .introPar{
        max-width:75%;
    }
  
    
    
    @media only screen and (max-width:830px){
       
            
        p{
            font-size: 1.25rem;
            line-height: 1.5;
            padding:.5em;
            text-align: center;
        }
        .introPar{
            max-width:100%;
        }
        
       
    }
}
@media only screen and (max-width:650px){
    p{
        font-size: 1rem;
    }
  
    .introPar{
        max-width:100%;
    }
    
   .portfolio h5{
       font-size: 1.1rem;
   }

  



}
   
